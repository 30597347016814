import React from "react"
import { graphql } from "gatsby"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const variantOverview = ({ data }) => {
  const variantsDE = data.variantsDE.edges
  const variantsEN = data.variantsEN.edges
  const variantsFR = data.variantsFR.edges
  return (
    <>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Varianten DE:</h1>
        <div className="flex flex-wrap">
          {variantsDE.map((variant) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={variant.node.fullSlug}>
                {variant.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Varianten EN:</h1>
        <div className="flex flex-wrap">
          {variantsEN.map((variant) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={variant.node.fullSlug}>
                {variant.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Varianten FR:</h1>
        <div className="flex flex-wrap">
          {variantsFR.map((variant) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={variant.node.fullSlug}>
                {variant.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const data = graphql`
  {
    variantsDE: allContentServVariant(
      filter: { locale: { eq: "de" }, groupId: { ne: null } }
    ) {
      edges {
        node {
          name
          locale
          fullSlug
          groupId
        }
      }
    }
    variantsEN: allContentServVariant(
      filter: { locale: { eq: "en" }, groupId: { ne: null } }
    ) {
      edges {
        node {
          name
          locale
          fullSlug
          groupId
        }
      }
    }
    variantsFR: allContentServVariant(
      filter: { locale: { eq: "fr" }, groupId: { ne: null } }
    ) {
      edges {
        node {
          name
          locale
          fullSlug
          groupId
        }
      }
    }
  }
`

export default variantOverview
